import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import global from "./mixins/global";
import Popover from "vue-js-popover";
import VPopover from "vue-js-popover";

import "./index.css";

// Import Moment component to disply current month
import moment from "moment";
import "moment/locale/nl";
moment.locale("nl");

Vue.config.productionTip = false;
Vue.mixin(global);
Vue.use(Popover);
Vue.use(VPopover, { tooltip: true });
Vue.prototype.moment = moment;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
