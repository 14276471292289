<template>
  <div class="hoeveel">
    <div class="container text-center py-4">
      <h1 class="py-4">Hoeveel kan je besparen?</h1>
      <div class="py-4 grid lg:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 gap-4">
        <div
          class="h-full w-full block max-w-sm p-6 bg-white shadow-lg hover:bg-gray-100 m-auto"
        >
          <img :src="icon1" class="m-auto mb-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Koop- of huurwoning
          </h6>
          <p class="font-normal text-gray-700">
            De gemeente vergoedt de gemaakte kosten (gratis)
          </p>
          <img :src="divider" class="mx-auto my-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Besparing
          </h6>
          <p class="font-normal text-gray-700">Gemiddelde besparing</p>
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-primary">
            € 272
          </h6>
          <img :src="divider" class="mx-auto my-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Kosten
          </h6>
          <p class="font-normal text-gray-700">Gratis</p>
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-secondary">
            € 0,-
          </h6>
        </div>
        <div
          class="h-full w-full block max-w-sm p-6 bg-white shadow-lg hover:bg-gray-100 m-auto"
        >
          <img :src="icon2" class="m-auto mb-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Meerdere adressen
          </h6>
          <p class="font-normal text-gray-700">
            De gemeente vergoedt de gemaakte kosten (gratis)
          </p>
          <img :src="divider" class="mx-auto my-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Besparing
          </h6>
          <p class="font-normal text-gray-700">Gemiddelde besparing</p>
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-primary">
            € 737
          </h6>
          <img :src="divider" class="mx-auto my-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Kosten
          </h6>
          <p class="font-normal text-gray-700">Gratis</p>
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-secondary">
            € 0,-
          </h6>
        </div>
        <div
          class="h-full w-full block max-w-sm p-6 bg-white shadow-lg hover:bg-gray-100 m-auto"
        >
          <img :src="icon3" class="m-auto mb-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Erfkwestie
          </h6>
          <br />
          <img :src="divider" class="mx-auto my-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Besparing
          </h6>
          <p class="font-normal text-gray-700">Gemiddelde besparing</p>
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-primary">
            € 3.368
          </h6>
          <img :src="divider" class="mx-auto my-3" />
          <h6 class="mb-2 text-xl font-semibold tracking-tight text-gray-900">
            Kosten
          </h6>
          <p class="font-normal text-gray-700">Afhankelijk van besparing</p>
        </div>
      </div>
      <router-link
        to="/form"
        class="no-underline mt-6 mb-1 inline-block px-7 py-4 bg-green-600 border-b-2 border-green-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
      >
        <span
          class="flex items-center font-base font-bold text-sm md:text-base"
        >
          Bereken mijn besparing <img class="ml-5" :src="arrowIcon"
        /></span>
      </router-link>
      <h6 class="mb-2 mt-7 text-xl font-semibold tracking-tight text-secondary">
        <!-- Hoe kunnen jullie dit gratis aanbieden? -->
        Allereerst, is het echt gratis?
      </h6>
      <!-- <p class="mb-5">
        Goede vraag! Wij maken
        <b> gratis bezwaar voor woningbezitters en sociale huurders</b>. Als het
        bezwaar gegrond is, betaalt uw gemeente onze kosten op grond van het
        wettelijk stelsel. Indien het bezwaarschrift door de gemeente wordt
        afgewezen, dragen wij de kosten van de procedure.
      </p> -->
      <p class="mb-5">
        <b> Jazeker! </b>
        Bezwaarmaker.nl maakt gratis bezwaar voor woningbezitters en sociale
        huurders. Als het bezwaar gegrond is,<b>
          betaalt uw gemeente onze kosten</b
        >
        op grond van het wettelijk stelsel. Indien het bezwaarschrift door de
        gemeente wordt afgewezen,<b>
          dragen wij de kosten van de procedure. <br />

          <br /></b
        >U loopt dus <b>geen risico</b> en met een slagingspercentage van 80% is
        de kans op succes zeer groot! <br /><br />
        Als gevolg van een te hoge WOZ-Waarde betalen Nederlanders elk jaar
        honderden miljoenen euro te veel aan belastingen. De waarde wordt
        automatisch bepaald en houdt geen rekening met jouw specifieke
        omstandigheden. Weinig mensen weten dit.
        <b> Maak gebruik van uw recht en betaal niet te veel belasting!</b>
        <br />
        <br />
        <b>
          Kom in actie voordat het te laat is en laat Bezwaarmaker.nl nu gratis
          bezwaar maken voor u.</b
        >
      </p>
    </div>
  </div>
</template>

<script>
import icon1 from "@/assets/icon1.png";
import icon2 from "@/assets/icon2.png";
import icon3 from "@/assets/icon3.png";
import divider from "@/assets/divider.png";
import arrowIcon from "@/assets/btnArrow.png";

export default {
  name: "Hoeveel",
  data() {
    return {
      icon1,
      icon2,
      icon3,
      divider,
      arrowIcon,
    };
  },
};
</script>

<style>
.hoeveel {
  background-color: #ecf0f1;
}
</style>
