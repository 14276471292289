import PostcodeRepository from "./PostcodeRepository";

export default {
  getStreet(payload) {
    console.log("payload: ", payload);
    return PostcodeRepository.get(
      `?authKey=P6JTU52clKYjZca8&postalCode=${payload.postcode}&streetNumberAndPremise=${payload.house}`
    );
  },
};
