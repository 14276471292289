<template>
  <div class="footer">
    <div class="container flex justify-between pt-5 flex-col md:flex-row">
      <img :src="logo" />
      <p class="font-normal text-sm text-gray-700 text-center">
        <span
          data-modal-target="defaultModal"
          data-modal-toggle="defaultModal"
          class="cursor-pointer hover:text-black"
          >Privacybeleid</span
        >
        |
        <span
          data-modal-target="voorwaarden"
          data-modal-toggle="voorwaarden"
          class="cursor-pointer hover:text-black"
          >Algemene voorwaarden</span
        >
      </p>
    </div>

    <!-- Main modal -->
    <div
      id="defaultModal"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div class="relative w-full h-full max-w-2xl md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-4 border-b rounded-t">
            <h3 class="text-xl font-semibold text-gray-900">Privacybeleid</h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="defaultModal"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6 modalBody">
            <h2>Privacy beleid</h2>
            <p>
              Uw privacy is voor Bezwaarmaker.nl van groot belang. Wij houden
              ons dan ook aan de privacywet. Dit betekent dat uw gegevens veilig
              bij ons zijn en dat wij ze netjes gebruiken. In deze
              privacyverklaring leggen we uit wat we bij de dienst
              www.bezwaarmaker.nl allemaal doen met informatie die we over u te
              weten komen.
            </p>

            <p>
              Als u vragen hebt, of wilt weten wat we precies van u bijhouden,
              neem dan contact op met Bezwaarmaker.nl.
            </p>

            <h3>Onze dienst</h3>

            <p>
              Wij maken gratis bezwaar voor woningbezitters. Als het bezwaar
              gegrond is betaalt uw gemeente onze kosten op grond van het
              wettelijke stelsel. Indien de waarde niet wordt verlaagt zijn de
              kosten voor onze rekening. In onze dienst verrichten wij het
              WOZ-bezwaar in 5 stappen. De eerste stap is dat u zich aanmeld,
              zie ook de volgende alinea. In stap 2 versturen wij een
              bezwaarschrift om de bezwaartermijn veilig te stellen. Vervolgens
              beoordelen wij uw WOZ-waarde en gaan op zoek naar de juiste
              motivatie om een lagere waarde te onderbouwen, dit is stap 3.
              Wanneer de gemeente niet voornemens is de waarde te verlagen zal
              als stap 4 een hoorzitting volgen. Stap 5 is het ontvangen van de
              uitspraak. Wij zullen deze beoordelen en vervolgens naar u
              versturen. Als we niet tevreden zijn met de uitspraak kunnen we in
              overleg met u in beroep gaan bij een rechtbank.
            </p>

            <p>
              Het is onze opzet u zoveel mogelijk werk uit handen te nemen, met
              als uitkomst dat u uw zaken goed heeft geregeld. Onze dienst heeft
              als doel u te ontzorgen. Om invulling aan dit doel te kunnen geven
              werken wij samen met partners. Onze partners zijn zorgvuldig
              geselecteerd en de diensten die zijn u bieden zullen ook als doel
              hebben u te ontzorgen. In onze selectie hebben wij ook beoordeeld
              en vastgesteld dat ook onze partners zorgvuldig met uw gegevens
              omgaan. Zie ook alinea Verstrekken aan andere bedrijven of
              instellingen.
            </p>

            <h3>Aanmeldformulier</h3>
            <p>
              Via www.bezwaarmaker.nl kunt u zich aanmelden voor onze dienst.
              Wij vragen u dan om NAW-gegevens, uw aanslagbiljet en gegevens
              over het pand waarop het bezwaar betrekking zal hebben. Deze
              gegevens worden in stap 4 van het aanmeldproces automatisch aan
              ons toegezonden. Deze gegevens hebben wij nodig om de dienst voor
              u uit te kunnen voeren en om contact met u op te kunnen nemen als
              wij aanvullende informatie nodig hebben of wij u op de hoogte
              willen houden van de status. We bewaren deze gegevens totdat het
              bezwaarproces is afgerond en 5 jaar daarna. Gegevens worden
              opgeslagen om bezwaarprocedures in volgende jaren makkelijker te
              maken en om u op de hoogte houden over de ontwikkelingen binnen de
              Wet WOZ.
            </p>

            <h3>Contactformulier en nieuwsbrief</h3>
            <p>
              Met het contactformulier kunt u ons vragen stellen of aanvragen
              doen. Voor het invullen van het contactformulier vragen wij
              e-mailadres, telefoonnummer en naam. Met deze gegevens kunnen wij
              contact met u opnemen om uw vraag of opmerking te behandelen. Wij
              bewaren deze informatie totdat wij uw vragen of opmerkingen hebben
              afgehandeld en u geen verdere vragen meer heeft.
            </p>

            <p>
              U kunt zich abonneren op onze nieuwsbrief. Hierin leest u
              nieuwtjes, tips en informatie over onze diensten. Dit abonnement
              kunt u op ieder moment opzeggen. Iedere nieuwsbrief bevat een
              afmeldlink. Via uw account kunt u dit ook doorgeven. Via het
              portaal kunt u dit ook doorgeven. Uw e-mailadres wordt automatisch
              toegevoegd aan de lijst van abonnees. Deze gegevens worden bewaard
              totdat u het abonnement opzegt of maximaal een jaar nadat wij u de
              laatste nieuwsbrief hebben toegezonden.
            </p>

            <h3>Reclame</h3>
            <p>
              Wij willen u graag reclame sturen over aanbiedingen en nieuwe
              producten of diensten. Dit doen wij:<br />
              - per e-mail<br />
              - via social media<br />
              U kunt op ieder moment bezwaar maken tegen deze reclame. U kunt
              ons blokkeren of de afmeldoptie gebruiken. Via uw account kunt u
              dit ook doorgeven.
            </p>

            <h3>Uw account</h3>
            <p>
              Bij bepaalde onderdelen van onze dienst moet u zich eerst
              registreren. U moet dan informatie over uzelf opgeven en een
              gebruikersnaam kiezen. Daarmee maken wij een account, waarop u
              kunt inloggen met die gebruikersnaam en een zelfgekozen
              wachtwoord.
            </p>

            <p>
              Hiervoor gebruiken wij uw NAW-gegevens, telefoonnummer,
              e-mailadres, ip-adres, functie, bedrijfsnaam, type pand, foto’s en
              gegevens aanslagbiljet, factuuradres, betalingsgegevens en uw
              geslacht. Deze gegevens hebben wij nodig voor de uitvoering van de
              overeenkomst die wij met u sluiten. Wij bewaren deze informatie
              tot u het account opheft. Wij bewaren dan wel nog de gegevens die
              wij op grond van wetgeving moeten bewaren. Denk hierbij aan
              gegevens voor de belastingdienst, deze bewaren wij maximaal 7
              jaar. U kunt via uw account informatie aanpassen wanneer u dat
              wilt.
            </p>

            <h3>Toegang portaal</h3>
            <p>
              In ons portaal krijgt u toegang tot een beheeromgeving waar u zelf
              dingen kunt instellen, opgeven en wijzigen voor de dienst. U kunt
              hier bijvoorbeeld uw NAW-gegevens opgeven of wijzigen en gegevens
              over het pand waarvoor u een bezwaar wenst in te dienen doorgeven.
              Wij houden bij wat u gedaan heeft en wanneer, zodat daar bewijs
              van is. Dit doen wij op basis van uw toestemming. Wij bewaren deze
              informatie totdat u uw account opheft.
            </p>

            <h3>Statistieken en profilering</h3>
            <p>
              Wij houden statistieken bij over het gebruik van onze dienst. Met
              deze statistieken verbeteren we onze dienst om zo bijvoorbeeld
              alleen nog relevante informatie te laten zien. Wij kunnen uw
              persoonsgegevens met elkaar combineren om meer over u te weten te
              komen. Uiteraard zullen wij uw privacy te allen tijde respecteren.
              Wilt u dit niet, dan kunt u dit altijd aan ons melden. Voor het
              verwerken van deze gegevens gebruiken wij cookies. Deze cookies
              verzamelen uw ip-adres, zoekgeschiedenis en combineren dit met
              gegevens uit andere cookies.
            </p>

            <p>
              De cookies plaatsen wij alleen na uw toestemming. Wij bewaren deze
              informatie voor drie maanden.
            </p>

            <h3>Locatiegegevens</h3>
            <p>
              Soms is het nodig voor onze dienst www.bezwaarmaker.nl om te
              achterhalen op welke plek u bent. We vragen dan apart of we dit
              mogen weten en alleen als u dan toestemming geeft, krijgen wij
              deze informatie. Deze informatie wordt gedeeltelijk gebruikt om
              bij uw gemeente aan te kunnen tonen wie zich bij ons heeft
              aangemeld en zijn nodig voor marketingdoeleinden.
            </p>

            <p>
              Voor deze dienst gebruiken wij de navigatie- en locatiesoftware op
              uw telefoon of computer. Wij hebben geen controle over wat de
              makers van deze software (zoals Google Maps) daarmee doen. Lees
              dus altijd ook hun privacyverklaringen.
            </p>

            <h3>Advertenties</h3>
            <p>
              Onze dienst vertoont advertenties.Deze publiceren wij niet zelf of
              advertentiebureaus waarmee wij samenwerken. Deze bureaus houden
              bij of u ze leuk vindt, en gebruiken die informatie om in de
              toekomst alleen nog maar leuke of interessante advertenties en
              aanbiedingen te doen.
            </p>

            <h3>Verstrekking aan andere bedrijven of instellingen</h3>
            <p>
              Wij geven uw persoonsgegevens alleen aan andere bedrijven of
              instellingen als dat nodig is voor onze dienst of als wij dat
              wettelijk verplicht zijn, bijvoorbeeld als de politie dat eist bij
              een vermoeden van een misdrijf.
            </p>

            <p>
              Wij werken met bepaalde bedrijven samen. Deze partners kunnen uw
              persoonsgegevens krijgen. Dit zijn onze hostingpartij, ons
              Administratiekantoor, onze partners en het call center dat u te
              woord staat als u contact met ons opneemt. Ook marketingbedrijven
              kunnen toegang krijgen tot uw gegevens. Zij zorgen er voor dat
              advertenties zo veel mogelijk interessante of leuke aanbiedingen
              bevatten. Op onze website zijn social media buttons opgenomen.
              Hiermee verzamelen de beheerders van deze diensten uw
              persoonsgegevens.
            </p>

            <p>
              Als wij een bezwaarschrift indienen zullen wij ook gegevens
              doorgeven aan de gemeente waarin uw pand gevestigd is. Dit is
              noodzakelijk, zodat deze gemeente het bezwaar kan beoordelen.
            </p>

            <h3>Beveiliging</h3>
            <p>
              Beveiliging van persoonsgegevens is voor ons van groot belang. Wij
              zorgen dat uw gegevens bij ons goed beveiligd zijn. Onze volledige
              website is beveiligd middels een SSL certificaat. De gegevens die
              je bij ons achterlaat worden beveiligd naar onze server verzonden.
              We passen de beveiliging steeds aan naar de nieuwe technische
              stand.
            </p>

            <h3>Wijzigingen in deze privacyverklaring</h3>
            <p>
              Wanneer onze dienst wijzigt, moeten wij natuurlijk ook de
              privacyverklaring aanpassen. Let dus altijd op de datum hierboven
              en kijk regelmatig of er nieuwe versies zijn.
            </p>

            <h3>Inzage en wijzigen van uw gegevens</h3>
            <p>
              Als u vragen hebt of wilt weten welke persoonsgegevens wij van u
              hebben, kunt u altijd contact met ons opnemen. Zie de
              contactgegevens hieronder.
            </p>

            <p>U hebt de volgende rechten:</p>

            <p>
              uitleg krijgen over welke persoonsgegevens we hebben en wat we
              daarmee doen;<br />
              inzage in de precieze persoonsgegevens die we hebben;<br />
              het laten corrigeren van fouten;<br />
              het laten verwijderen van verouderde persoonsgegevens;<br />
              intrekken van toestemming;<br />
              bezwaar maken tegen een bepaald gebruik.<br />
              Let op dat u altijd duidelijk aangeeft wie u bent, zodat we zeker
              weten dat we geen gegevens van de verkeerde persoon aanpassen of
              verwijderen.
            </p>

            <h3>Klacht indienen</h3>
            <p>
              Als u vindt dat wij u niet op de juiste manier helpen, dan heeft u
              het recht om een klacht in te dienen bij de toezichthouder. Deze
              heet de Autoriteit Persoonsgegevens.
            </p>

            <p>
              Contactgegevens<br />
              Bezwaarmaker.nl<br />
              Witte Paal 333<br />
              1742LE Schagen<br />
              info@bezwaarmaker.nl<br />
              0857730297
            </p>

            <h3>Inwerkingtreding</h3>
            <p>
              eze (vernieuwde) privacyverklaring is in werking getreden op 24
              mei 2018.
            </p>
          </div>
          <!-- Modal footer -->
          <div
            class="flex items-center px-6 py-2 space-x-2 border-t border-gray-200 rounded-b"
          >
            <button
              data-modal-hide="defaultModal"
              type="button"
              class="inline-block px-6 py-2 bg-green-600 border-b-2 border-green-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
    <!--  Algemene voorwaarden -->
    <div
      id="voorwaarden"
      tabindex="-1"
      aria-hidden="true"
      class="fixed top-0 left-0 right-0 z-50 hidden w-full p-4 overflow-x-hidden overflow-y-auto md:inset-0 h-modal md:h-full"
    >
      <div class="relative w-full h-full max-w-2xl md:h-auto">
        <!-- Modal content -->
        <div class="relative bg-white rounded-lg shadow">
          <!-- Modal header -->
          <div class="flex items-start justify-between p-4 border-b rounded-t">
            <h3 class="text-xl font-semibold text-gray-900">
              Algemene voorwaarden
            </h3>
            <button
              type="button"
              class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center"
              data-modal-hide="voorwaarden"
            >
              <svg
                aria-hidden="true"
                class="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Close modal</span>
            </button>
          </div>
          <!-- Modal body -->
          <div class="p-6 space-y-6 modalBody">
            <h2>Algemene voorwaarden</h2>
            <h3>Artikel 1 Algemeen</h3>

            <p>
              Bezwaarmaker.nl heeft ten doel het verlenen van rechtsbijstand en
              juridische adviezen, het verrichten van taxaties en werkzaamheden
              inzake de Wet waardering onroerende zaken, lokale heffingen,
              basisregistraties en overige taxaties van onroerende zaken in de
              breedste zin van het woord, op grond van welke titel dan ook.
            </p>

            <h3>Artikel 2 Definities</h3>

            <p>
              In deze algemene voorwaarden worden de volgende termen, ook welke
              in meer- c.q. enkelvoud zijn vervoegd, in de navolgende betekenis
              gebruikt, voor zover uit de aard of strekking van de bedingen niet
              anders voortvloeit.
            </p>

            <p>
              2.1 Bezwaarmaker.nl: onderdeel van Bezwaarmaker B.V., de gebruiker
              van deze algemene voorwaarden, gevestigd aan Rensgars 7, 1741BR te
              Schagen, ingeschreven in het Handelsregister onder KvK-nummer
              72165359.
            </p>

            <p>
              2.2 Opdrachtgever: iedere natuurlijke of rechtspersoon die
              Bezwaarmaker.nl een opdracht heeft verstrekt of beoogt te
              verstrekken.
            </p>

            <p>
              2.3 Partijen: Bezwaarmaker.nl en de opdrachtgever gezamenlijk.
            </p>

            <p>
              2.4 Opdracht: iedere tussen Bezwaarmaker.nl en de opdrachtgever
              tot stand gekomen overeenkomst waarmee Bezwaarmaker.nl zich jegens
              de opdrachtgever heeft verbonden om als gemachtigde van de
              opdrachtgever bezwaar of beroep in te dienen tegen vastgestelde
              WOZ-waarde(s).
            </p>

            <p>
              2.5 Schriftelijk: communicatie op schrift, communicatie per
              e-mail, per fax of enige andere wijze van communicatie die met het
              oog op de stand der techniek en de in het maatschappelijk verkeer
              geldende opvattingen hiermee gelijk kan worden gesteld.
            </p>

            <h3>Artikel 3 Toepasselijkheid</h3>

            <p>
              3.1 Deze algemene voorwaarden zijn van toepassing op elk aanbod
              van Bezwaarmaker.nl en iedere opdracht, waaronder mede begrepen
              eventuele aanvullende en vervolgopdrachten.
            </p>

            <p>
              3.2 Van het bepaalde in deze algemene voorwaarden kan uitsluitend
              uitdrukkelijk en schriftelijk worden afgeweken. Indien en voor
              zover hetgeen partijen uitdrukkelijk en schriftelijk zijn
              overeengekomen, afwijkt van het bepaalde in deze algemene
              voorwaarden, geldt hetgeen partijen uitdrukkelijk en schriftelijk
              zijn overeengekomen.
            </p>

            <p>
              3.3 Vernietiging en/of de nietigheid van enige bepaling uit deze
              algemene voorwaarden of de overeenkomst als zodanig, laat de
              geldigheid van de overige bepalingen onverlet.
            </p>

            <p>
              3.4 De toepasselijkheid van de eventuele algemene voorwaarden van
              de opdrachtgever wordt uitdrukkelijk van de hand gewezen,
              onverminderd de mogelijkheid van partijen de toepasselijkheid
              daarvan toch geheel of gedeeltelijk uitdrukkelijk en schriftelijk
              overeen te komen.
            </p>

            <h3>Artikel 4 Opdrachten</h3>

            <p>
              4.1 Een opdracht wordt eerst geacht door Bezwaarmaker.nl te zijn
              aanvaard en daarmee tot stand te zijn gekomen, na schriftelijke
              bevestiging daarvan door Bezwaarmaker.nl aan de opdrachtgever, dan
              wel, bij ontbreken daarvan, nadat Bezwaarmaker.nl, met kennelijke
              instemming van de opdrachtgever, met de opgedragen werkzaamheden
              een feitelijke aanvang heeft gemaakt.
            </p>

            <p>
              4.2 Iedere opdracht wordt geacht uitsluitend te zijn verstrekt aan
              en te zijn aanvaard door Bezwaarmaker.nl.
            </p>

            <p>
              4.3 Bij de uitvoering van de opdracht is Bezwaarmaker.nl
              gerechtigd om derden in te schakelen. De toepasselijkheid van
              artikel 7:404 en 7:407 lid 2 van het Burgerlijk Wetboek is
              uitgesloten. Indien Bezwaarmaker.nl derden inschakelt bij de
              uitvoering van de opdracht, zal daarover tevoren zoveel mogelijk
              met de opdrachtgever worden overlegd. Iedere eigen
              aansprakelijkheid van Bezwaarmaker.nl voor een fout of
              tekortkoming van door Bezwaarmaker.nl ingeschakelde derden is
              uitgesloten. Voor zover deze derden hun aansprakelijkheid voor
              beroepsfouten jegens Bezwaarmaker.nl beperken, is Bezwaarmaker.nl
              gerechtigd om een dergelijke aansprakelijkheidsbeperking mede
              namens de opdrachtgever te aanvaarden.
            </p>

            <p>
              4.4 Bezwaarmaker.nl is te allen tijde bevoegd om een opdracht te
              weigeren of tussentijds op te zeggen, zulks onder opgave van
              redenen en zonder dat Bezwaarmaker.nl enige schadevergoeding aan
              de opdrachtgever verschuldigd is.
            </p>

            <h3>Artikel 5 Gegevensverstrekking</h3>

            <p>
              5.1 De opdrachtgever draagt er zorg voor dat alle informatie en
              eventuele overige benodigdheden, waarvan Bezwaarmaker.nl aangeeft
              dat deze noodzakelijk zijn of waarvan de opdrachtgever
              redelijkerwijs behoort te begrijpen dat deze noodzakelijk zijn
              voor het uitvoeren van de opdracht, tijdig aan Bezwaarmaker.nl
              worden verstrekt.
            </p>

            <p>
              5.2 In het bijzonder dient de opdrachtgever alle voor de
              uitvoering van het bezwaar of beroep relevante bescheiden zo
              spoedig mogelijk, doch uiterlijk twee werkdagen vóór het
              verstrijken van de termijn waarbinnen het bezwaar of beroep moet
              worden ingediend, aan Bezwaarmaker.nl te doen toekomen. Indien de
              voornoemde bescheiden op een later tijdstip worden overlegd,
              behoudt Bezwaarmaker.nl zich het recht voor het betreffende
              dossier niet in behandeling te nemen. Voor de eventuele schade
              voortvloeiende uit een niet tijdig ingediend bezwaar- en/of
              beroepschrift, is Bezwaarmaker.nl niet aansprakelijk.
            </p>

            <h3>Artikel 6 Opschorting en ontbinding</h3>

            <p>
              6.1 Bezwaarmaker.nl is, indien de omstandigheden van het geval dat
              redelijkerwijs rechtvaardigen, bevoegd de uitvoering van de
              opdracht op te schorten of de opdracht met directe ingang geheel
              of gedeeltelijk te ontbinden, indien en voor zover de
              opdrachtgever zijn verplichtingen uit de opdracht, met name wat
              betreft gegevensverstrekking, niet, niet tijdig of niet volledig
              nakomt, dan wel na het aangaan van de opdracht Bezwaarmaker.nl ter
              kennis gekomen omstandigheden goede grond geven te vrezen dat de
              opdrachtgever zijn verplichtingen niet zal nakomen. Indien de
              nakoming van de verplichtingen van de opdrachtgever ten aanzien
              waarvan hij tekortschiet of dreigt te kort te schieten, niet
              blijvend onmogelijk is, ontstaat de bevoegdheid tot ontbinding pas
              nadat de opdrachtgever schriftelijk door Bezwaarmaker.nl in
              gebreke is gesteld, in welke ingebrekestelling een redelijke
              termijn is vermeld waarbinnen de opdrachtgever zijn verplichtingen
              (alsnog) kan nakomen en de nakoming na verstrijken van de
              laatstbedoelde termijn nog steeds is uitgebleven.
            </p>

            <p>
              6.2 Indien Bezwaarmaker.nl de opdracht opschort of ontbindt, is
              Bezwaarmaker.nl niet aansprakelijk voor eventueel door de
              opdrachtgever geleden schade.
            </p>

            <h3>Artikel 7 Aansprakelijkheid, vrijwaring en klachten</h3>

            <p>
              7.1 Verstrekte opdrachten worden door Bezwaarmaker.nl uitsluitend
              uitgevoerd ten behoeve van de opdrachtgever. Derden kunnen aan de
              wijze waarop aan de opdracht al dan niet uitvoering is gegeven,
              geen rechten ontlenen en de opdrachtgever vrijwaart
              Bezwaarmaker.nl van aanspraken van derden dienaangaande.
            </p>

            <p>
              7.2 Bezwaarmaker.nl is niet aansprakelijk voor schade, van welke
              aard dan ook, veroorzaakt door en/of die verband houdt met
              onjuistheden of onvolledigheden in de door de opdrachtgever aan
              Bezwaarmaker.nl verstrekte gegevens, iedere andere tekortkoming in
              de nakoming van de verplichtingen van de opdrachtgever die uit de
              wet of de overeenkomst voortvloeien, alsmede een andere
              omstandigheid die niet aan Bezwaarmaker.nl kan worden toegerekend.
              Bezwaarmaker.nl is onder meer dan ook niet aansprakelijk voor het
              niet of niet tijdig indienen van een bezwaar- of beroepsschrift
              als gevolg van fouten of tekortkomingen van derden, bijvoorbeeld
              postinstanties.
            </p>

            <p>
              7.3 Bezwaarmaker.nl is nimmer aansprakelijk voor indirecte schade.
              Bezwaarmaker.nl is, onverminderd het bepaalde in het overige van
              deze algemene voorwaarden en met name het bepaalde in artikel 7.4,
              jegens de opdrachtgever slechts aansprakelijk te houden voor
              directe schade die de opdrachtgever lijdt als gevolg van een
              toerekenbare tekortkoming van Bezwaarmaker.nl in de nakoming van
              de overeenkomst. Onder toerekenbare tekortkoming moet worden
              verstaan een tekortkoming die een goed en zorgvuldig handelend
              vakgenoot kan en behoort te vermijden, een en ander met
              inachtneming van normale oplettendheid en de voor de uitvoering
              van de opdracht vereiste vakkennis en middelen.
            </p>

            <p>
              7.4 Behoudens opzet en bewuste roekeloosheid van Bezwaarmaker.nl,
              is de aansprakelijkheid van Bezwaarmaker.nl voor schade verband
              houdende met de uitvoering van de opdracht, beperkt tot ten
              hoogste het honorarium dat Bezwaarmaker.nl in het kader van de
              opdracht heeft ontvangen of in rekening heeft gebracht.
            </p>

            <p>
              7.5 Mocht Bezwaarmaker.nl aansprakelijk zijn voor enige schade,
              dan heeft Bezwaarmaker.nl te allen tijde het recht deze schade te
              herstellen. De opdrachtgever dient Bezwaarmaker.nl hiertoe in de
              gelegenheid te stellen, bij gebreke waarvan elke aansprakelijkheid
              van Bezwaarmaker.nl ter zake vervalt.
            </p>

            <p>
              7.6 De opdrachtgever vrijwaart Bezwaarmaker.nl van zijn eventuele
              aanspraken en aanspraken van derden voor schade waarvan de oorzaak
              aan anderen dan Bezwaarmaker.nl toerekenbaar is.
            </p>

            <p>
              7.7 Een klacht met betrekking tot de door Bezwaarmaker.nl in het
              kader van de opdracht verrichte werkzaamheden, dient, op straffe
              van verval van alle aanspraken, binnen 30 dagen nadat de
              opdrachtgever de gronden die tot de klacht aanleiding gaven, heeft
              ontdekt, althans redelijkerwijs had kunnen ontdekken, schriftelijk
              bij Bezwaarmaker.nl te worden ingediend. Klachten schorten de
              betalingsverplichting van de opdrachtgever niet op.
            </p>

            <p>
              7.8 Geen enkele uitlating op deze site is als persoonlijk
              financieel advies bedoeld.
            </p>

            <h3>Artikel 8 Intellectuele eigendom</h3>

            <p>
              Bezwaarmaker.nl behoudt zich alle hem krachtens de wet toekomende
              rechten van intellectuele eigendom voor. De opdrachtgever is,
              zonder voorafgaande schriftelijke toestemming van Bezwaarmaker.nl,
              niet gerechtigd tot het vermenigvuldigen, openbaar maken of ter
              kennis brengen van rapporten, presentaties, adviezen, materialen,
              teksten enz. welke de opdrachtgever in het kader van de opdracht
              van Bezwaarmaker.nl heeft ontvangen. De opdrachtgever vrijwaart
              Bezwaarmaker.nl voorts van alle eventuele aanspraken van derden
              indien opdrachtgever dit verbod overtreedt.
            </p>

            <h3>
              Artikel 9 No cure No pay/Gratis voor Woningbezitter &
              betalingsvoorwaarden
            </h3>

            <p>
              9.1 De opdracht wordt verricht op basis van no cure no pay, tenzij
              uitdrukkelijk en schriftelijk anders is overeengekomen of door
              Bezwaarmaker.nl uitdrukkelijk anders is vermeld. In een voorkomend
              geval wordt het verrichten van de opdracht op basis van no cure no
              pay, waar nodig, door Bezwaarmaker.nl bevestigd middels de
              opdrachtbevestiging. Indien de voorafgaande bezwaarprocedure niet
              via Bezwaarmaker.nl is verlopen en de opdrachtgever
              Bezwaarmaker.nl opdracht geeft tot het uitvoeren van een
              beroepsprocedure, kunnen partijen overeenkomen dat Bezwaarmaker.nl
              die opdracht uitvoert tegen betaling van het dan geldende
              uurtarief van Bezwaarmaker.nl. Bezwaarmaker.nl zal hiervan
              voorafgaand mededeling doen aan de opdrachtgever.
            </p>

            <p>
              9.2 Indien Bezwaarmaker.nl op basis van no cure no pay bezwaar
              maakt voor particuliere woningbezitters, de bezwaarprocedure
              betrekking heeft op twee of minder adressen en het bezwaar gegrond
              is, betaalt de gemeente de kosten op grond van het wettelijk
              stelsel. Indien het bezwaarschrift door de gemeente wordt
              afgewezen, draagt Bezwaarmaker.nl de kosten van de procedure.
            </p>

            <p>
              9.3 In alle gevallen komt de door een overheidsorgaan in het kader
              van de opdracht betaalde proceskostenvergoeding toe aan
              Bezwaarmaker.nl. De opdrachtgever verplicht zich om het bedrag
              voor de vergoeding van de proceskosten zoals vermeld in de
              artikelen 7:15 Awb en 8:75 Awb, dat door een overheidsorgaan
              eventueel is overgemaakt op zijn bankrekening in het kader van
              door Bezwaarmaker.nl in opdracht van de opdrachtgever verrichte
              werkzaamheden, binnen twee weken na ontvangst te storten op een
              door Bezwaarmaker.nl aangewezen bankrekening.
            </p>

            <p>
              9.4 Indien sprake is van een erfbelastingprocedure, brengt
              Bezwaarmaker.nl naast de vergoeding ter hoogte van de
              proceskostenvergoeding, een additionele vergoeding in rekening van
              1,5 % over het verschil van de oude en nieuwe WOZ-waarde. De
              opdrachtgever is evenwel alleen kosten verschuldigd bij een
              verlaging van de WOZ-waarde.
            </p>

            <p>
              9.5 Indien een opdracht door Bezwaarmaker.nl wordt uitgevoerd op
              basis van no cure no pay, worden door de opdrachtgever zonder
              voorafgaande uitdrukkelijke instemming van Bezwaarmaker.nl, geen
              beslissingen genomen dan wel mededelingen gedaan betreffende
              ingestelde bezwaar-/beroepsprocedures bij een overheidsorgaan,
              welke van invloed kunnen zijn op de uitkomst van de ingestelde
              procedures inzake het treffen van schikkingen of intrekken van
              ingestelde procedures.
            </p>

            <p>
              9.6 Bezwaarmaker.nl is te allen tijde gerechtigd om
              (gerechtelijke) procedures op te starten ten behoeve van het
              verkrijgen van een proceskostenvergoeding uit hoofde van de uit te
              voeren opdracht. Opdrachtgever verplicht zich ertoe hieraan zijn
              medewerking te verlenen, bij gebreke waarvan hetgeen is
              overeengekomen op basis van no cure no pay vervalt.
            </p>

            <p>
              9.7 Indien de gemeente een vergoeding betaalt aan de opdrachtgever
              op grond van ‘Wet dwangsom en beroep bij niet tijdig beslissen’,
              welke voortvloeit uit een actie van Bezwaarmaker.nl, komen de
              gelden die uit die dwangsom(men) voortvloeien, ten gunste van
              Bezwaarmaker.nl.
            </p>

            <p>
              9.8 Indien de opdrachtgever de opdracht wil intrekken nadat een
              pro-forma en/of gemotiveerd bezwaarschrift bij de gemeente of een
              beroepschrift bij de rechtbank is ingediend, worden de gemaakte
              kosten in rekening gebracht bij de opdrachtgever. De vergoeding
              bedraagt € 50,- inclusief btw.
            </p>

            <p>
              9.9 Betalingen uit hoofde van de opdracht, door de opdrachtgever
              aan Bezwaarmaker.nl te voldoen, dienen te geschieden middels
              overboeking, binnen 14 dagen na factuurdatum.
            </p>

            <p>
              9.10 Indien tijdige betaling door de opdrachtgever achterwege
              blijft, zal de opdrachtgever een herinnering ontvangen. Volgt
              binnen de daarin gestelde termijn geen betaling, dan zal de
              opdrachtgever een aanmaning ontvangen. Indien ook daaraan geen
              gehoor wordt gegeven, zal Bezwaarmaker.nl een invorderingsbureau
              inschakelen ter invordering van het factuurbedrag. De
              opdrachtgever is de wettelijke rente verschuldigd alsmede de (al
              dan niet gerechtelijke) kosten die Bezwaarmaker.nl redelijkerwijze
              dient te maken teneinde dit bedrag te verkrijgen. Voor de
              buitengerechtelijke kosten geldt het bepaalde in de Wet
              incassokosten (WIK).
            </p>

            <h3>Artikel 10 Geheimhouding</h3>

            <p>
              10.1 Bezwaarmaker.nl is verplicht tot geheimhouding van alle
              vertrouwelijke informatie die aan Bezwaarmaker.nl gedurende de
              werkzaamheden ter uitvoering van de opdracht als vertrouwelijk is
              toevertrouwd of ter kennis is gekomen of waarvan Bezwaarmaker.nl
              het vertrouwelijk karakter redelijkerwijs behoort te begrijpen,
              e.e.a. behoudens voor zover het delen van die informatie met
              derden noodzakelijk is ter uitvoering van de opdracht.
            </p>

            <p>
              10.2 Bezwaarmaker.nl behoudt het recht voor de eventueel door de
              uitvoering van de werkzaamheden toegenomen kennis voor andere
              doeleinden te gebruiken, voor zover hierbij geen vertrouwelijke
              informatie ter kennis van derden wordt gebracht.
            </p>

            <h3>Artikel 11 Commerciële aanbiedingen</h3>

            <p>
              Mits de opdrachtgever daarvoor uitdrukkelijk zijn toestemming
              heeft verleend, zal de opdrachtgever telefonisch of per e-mail
              voor commerciële doeleinden mogen worden benaderd door
              Bezwaarmaker.nl en/of partners van Bezwaarmaker.nl wat betreft
              besparingsmogelijkheden, bijvoorbeeld ten aanzien van
              verzekeringen, hypotheeklasten en onderhoud en/of verbouwing van
              onroerend goed. De opdrachtgever kan zijn toestemming te allen
              tijde intrekken bij Bezwaarmaker.nl, waarna deze intrekking zo
              spoedig mogelijk zal worden verwerkt en de opdrachtgever van de
              lijst van te benaderen personen zal worden verwijderd.
            </p>

            <h3>Artikel 12 Rechtskeuze</h3>

            <p>
              Op de rechtsverhouding tussen partijen is uitsluitend Nederlands
              recht van toepassing. Partijen zullen eerst een beroep op de
              rechter doen nadat zij zich tot het uiterste hebben ingespannen om
              het geschil in onderling overleg te beslechten.
            </p>
          </div>
          <!-- Modal footer -->
          <div
            class="flex items-center px-6 py-2 space-x-2 border-t border-gray-200 rounded-b"
          >
            <button
              data-modal-hide="voorwaarden"
              type="button"
              class="inline-block px-6 py-2 bg-green-600 border-b-2 border-green-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
            >
              Ok
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import logo from "@/assets/logo.svg";

export default {
  name: "Footer",
  data() {
    return {
      logo,
    };
  },
};
</script>

<style>
.footer {
  background: url(../assets/footer.png);
  height: 235px;
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
@media only screen and (max-width: 767.98px) {
  .footer {
    height: 145px;
    background-size: contain;
    background-position-y: bottom;
  }
}
.modalBody {
  height: 500px;
  overflow-y: scroll;
}
</style>
