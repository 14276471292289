// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import firebase from 'firebase/app';
import 'firebase/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyD_yMjzqCQ626PU1yPNoaJZAZVSAZjQb2Q",
    authDomain: "republish-platform.firebaseapp.com",
    projectId: "republish-platform",
    storageBucket: "republish-platform.appspot.com",
    messagingSenderId: "231604992498",
    appId: "1:231604992498:web:462c70b68454084a34d36b",
    measurementId: "G-WSS55C2SDT"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
const firestore = firebase.firestore();
export default firebase;
