<template>
  <!-- thankyou Complete request  -->
  <div>
    <div class="text-center my-auto">
      <h3>
        Bedankt voor uw aanvraag. We nemen zo snel mogelijk telefonisch contact
        met je op om je te informeren over hoe jij het beste kan besparen!
      </h3>
      <img
        v-if="profId != null"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.besparenopwoz.nl/m/5611/92418e40501d/?event=6556&unique_conversion_id=${profId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
    </div>
    <!-- thankyou Without assessment data  -->
    <!-- <div class="text-center my-auto">
      <h3>
        Bedankt voor uw aanvraag. We nemen zo snel mogelijk telefonisch contact
        met je op om je te informeren over hoe jij het beste kan besparen!
      </h3>
      <img
        v-if="profId != null"
        referrerpolicy="no-referrer-when-downgrade"
        :src="`https://republish.besparenopwoz.nl/m/5611/92418e40501d/?event=6665&unique_conversion_id=${profId}`"
        style="width: 1px; height: 1px; border: 0px"
      />
    </div> -->
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Bedankt",

  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "969816563708997"),
      fbq("track", "PageView"),
      fbq("track", "Lead")
    ),
      document.head.appendChild(useScript);
  },

  created() {
    document.title = "";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=969816563708997&ev=PageView&noscript=1"/></noscript>';
  },
  computed: {
    ...mapGetters(["profId"]),
  },
};
</script>

<style></style>
