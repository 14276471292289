import { mapGetters, mapActions } from "vuex";

export default {
  methods: {
    ...mapActions([
      "postLead",
      "getPostcodeData",
      "objectionRequest",
      "saveMissedMun",
      "getMunicipalityId",
    ]),
  },
  computed: {
    ...mapGetters(["getLoader", "profId"]),
  },
};
