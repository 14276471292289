<template>
  <div class="formSection py-5" id="form">
    <div class="container text-center">
      <h1 class="py-4 text-primary text-4lg">Gratis bezwaar maken</h1>

      <div class="h-full max-w-5xl bg-white shadow-lg m-auto">
        <div class="grid grid-cols-1 md:grid-cols-5">
          <div class="py-3" :class="step == 1 ? `bg-white` : `bg-gray-100`">
            <p class="font-normal fs--8 md:text-sm text-gray-700">
              1. Woninggegevens
            </p>
          </div>
          <div class="py-3" :class="step == 2 ? `bg-white` : `bg-gray-100`">
            <p class="font-normal fs--8 md:text-sm text-gray-700">
              2. Aanslaggegevens
            </p>
          </div>
          <div class="py-3" :class="step == 3 ? `bg-white` : `bg-gray-200`">
            <p class="font-normal fs--8 md:text-sm text-gray-700">
              3. Reden bezwaar
            </p>
          </div>
          <div class="py-3" :class="step == 4 ? `bg-white` : `bg-gray-300`">
            <p class="font-normal fs--8 md:text-sm text-gray-700">
              4. Contactgegevens
            </p>
          </div>
          <div class="py-3" :class="step == 5 ? `bg-white` : `bg-gray-400`">
            <p class="font-normal fs--8 md:text-sm text-gray-700">
              5. Afronden
            </p>
          </div>
        </div>
        <div class="p-6 text-left">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form ref="myForm" @submit.prevent="handleSubmit(handleForm)">
              <!-- form 1 -->
              <div
                class="grid md:grid-cols-3 sm:grid-cols-1 gap-5"
                v-if="step === 1"
              >
                <div class="col-span-2">
                  <p class="text-primary font-semibold mb-1">Adresgegevens</p>
                  <img :src="divider_lg" width="100%" />
                  <div class="mt-5">
                    <!-- type pand -->
                    <div class="mb-3">
                      <input
                        type="checkbox"
                        class="hidden-checkbox"
                        v-model="bootCheck"
                      />
                      <label
                        for="Type pand"
                        class="block mb-2 text-sm font-medium text-gray-700"
                        >Type woning</label
                      >
                      <select
                        id="Type pand"
                        class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                        :class="
                          propertyValidation == 1
                            ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                            : propertyValidation == 0
                            ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                            : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                        "
                        v-model="form.answers[2]"
                        required
                      >
                        <option value="null" disabled>Maak je keuze</option>
                        <option value="3283">Appartement</option>
                        <option value="3286">Hoekwoning</option>
                        <option value="3709">Tussenwoning</option>
                        <option value="3712">Twee onder een kap</option>
                        <option value="3715">Geschakelde woning</option>
                        <option value="3718">Vrijstaande woning</option>
                        <option value="3721">Bouwkavel</option>
                        <option value="3724">Garage of parkeerplaats</option>
                        <option value="3727">Overig</option>
                      </select>
                    </div>
                    <div class="grid grid-cols-3 gap-5 mb-3">
                      <!-- zip -->
                      <div>
                        <label
                          for="Postcode"
                          class="block mb-2 text-sm font-medium text-gray-700"
                          >Postcode</label
                        ><validation-provider
                          v-slot="{ errors }"
                          name="Postcode"
                          :rules="{
                            required: true,
                            regex: /^([0-9]{4}\s?[a-zA-Z]{2})$/,
                            ErrorResponse: {
                              target: getErrorResponses.zip,
                            },
                          }"
                        >
                          <input
                            type="text"
                            id="Postcode"
                            class="transition duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                            :class="
                              zipValidation == 1
                                ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                                : zipValidation == 0
                                ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                                : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                            "
                            v-model="form.zip"
                            :error-messages="errors"
                            @input="handleFieldInput('zip')"
                            required
                          />
                          <b-form-invalid-feedback
                            class="text-red-500"
                            v-if="zipValidation == 0"
                          >
                            Postcode niet correct
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                      <div class="grid-cols-3">
                        <!-- house -->
                        <label
                          for="Huisnummer"
                          class="block mb-2 text-sm font-medium text-gray-700"
                          >Huisnr</label
                        ><validation-provider
                          v-slot="{ errors }"
                          name="Huisnummer"
                          :rules="{
                            required: true,
                            regex: /^[0-9]*$/,
                            ErrorResponse: {
                              target: getErrorResponses.house_number,
                            },
                          }"
                        >
                          <input
                            type="text"
                            id="Huisnummer"
                            class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                            :class="
                              houseValidation == 1
                                ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                                : houseValidation == 0
                                ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                                : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                            "
                            v-model="form.house_number"
                            @input="handleFieldInput('house_number')"
                            :error-messages="errors"
                            required
                          />
                          <b-form-invalid-feedback
                            class="text-red-500"
                            v-if="houseValidation == 0"
                          >
                            Huisnummer niet correct
                          </b-form-invalid-feedback>
                        </validation-provider>
                      </div>
                      <div class="grid-cols-3">
                        <!-- house -->
                        <label
                          for="toev"
                          class="block mb-2 text-sm font-medium text-gray-700"
                          >toev</label
                        ><validation-provider
                          v-slot="{ errors }"
                          name="toev"
                          :rules="{
                            // required: true,
                            ErrorResponse: {
                              target: getErrorResponses.toev,
                            },
                          }"
                        >
                          <input
                            type="text"
                            id="toev"
                            class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                            :class="
                              toevValidation == 1
                                ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                                : toevValidation == 0
                                ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                                : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                            "
                            v-model="form.toev"
                            @input="handleFieldInput('toev')"
                            :error-messages="errors"
                          />
                          <!-- <b-form-invalid-feedback
                            class="text-red-500"
                            v-if="houseValidation == 0"
                          >
                            Huisnummer niet correct
                          </b-form-invalid-feedback> -->
                        </validation-provider>
                      </div>
                    </div>
                    <!-- straat -->
                    <div>
                      <label
                        for="Straat"
                        class="block mb-2 text-sm font-medium text-gray-700"
                        >Straat</label
                      >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Straat"
                        :rules="{
                          required: true,
                          ErrorResponse: {
                            target: getErrorResponses.street,
                          },
                        }"
                      >
                        <input
                          type="text"
                          id="Straat"
                          class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                          :class="
                            streetValidation == 1
                              ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                              : streetValidation == 0
                              ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                              : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                          "
                          v-model="form.street"
                          :error-messages="errors"
                          @input="handleFieldInput('street')"
                          required
                        />
                        <b-form-invalid-feedback
                          class="text-red-500"
                          v-if="streetValidation == 0"
                        >
                          Straatnaam niet correct
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                    <!-- plaats -->
                    <div>
                      <label
                        for="Plaats"
                        class="block my-2 text-sm font-medium text-gray-700"
                        >Plaats</label
                      >
                      <validation-provider
                        v-slot="{ errors }"
                        name="Plaats"
                        :rules="{
                          required: true,
                          ErrorResponse: {
                            target: getErrorResponses.city,
                          },
                        }"
                      >
                        <input
                          type="text"
                          id="Plaats"
                          class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                          :class="
                            cityValidation == 1
                              ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                              : cityValidation == 0
                              ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                              : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                          "
                          v-model="form.city"
                          @input="handleFieldInput('city')"
                          :error-messages="errors"
                          required
                        />
                        <b-form-invalid-feedback
                          class="text-red-500"
                          v-if="cityValidation == 0"
                        >
                          woonplaats niet correct
                        </b-form-invalid-feedback>
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
              <!-- form2 -->
              <div
                v-if="step == 2"
                class="grid md:grid-cols-3 sm:grid-cols-1 gap-5"
              >
                <div class="col-span-2">
                  <p class="text-primary font-semibold mb-1">Aanslaggegevens</p>
                  <img :src="divider_lg" width="100%" />
                  <!-- <p class="my-5">Heeft u uw aanslagbiljet bij de hand?</p>
                  <div
                    v-if="form.answers[1] == '3730'"
                    class="flex items-center mb-4"
                  >
                    <input
                      checked
                      id="default-radio-2"
                      type="radio"
                      value="true"
                      v-model="showBtn"
                      required
                      name="default-radio"
                      class="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2"
                    />
                    <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900"
                      >Ja</label
                    >
                    <input
                      id="default-radio-1"
                      type="radio"
                      value="false"
                      v-model="showBtn"
                      required
                      name="default-radio"
                      class="w-4 h-4 ml-8 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2"
                    />
                    <label
                      for="default-radio-1"
                      class="ml-2 text-sm font-medium text-gray-900"
                      >Nee</label
                    >
                  </div> -->
                  <div v-if="showBtn == `true` && step == 2">
                    <!-- Dagtekening -->
                    <div class="my-4">
                      <label
                        for="Dagtekening"
                        class="flex items-center justify-between mb-2 text-sm font-medium text-gray-700"
                        >Dagtekening
                        <button
                          class="hidden md:block"
                          v-popover:popover-1.right
                        >
                          <svg
                            class="w-4 h-4 ml-2 text-gray-400 hover:text-primary"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                        <button
                          class="block md:hidden"
                          v-popover:popover-1.left
                        >
                          <svg
                            class="w-4 h-4 ml-2 text-gray-400 hover:text-primary"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            ></path>
                          </svg></button
                      ></label>
                      <popover name="popover-1" :event="event">
                        <div class="p-3 space-y-2">
                          <p>
                            De dagtekening kunt u terugvinden op het
                            aanslagbiljet gemeentelijke belastingen
                          </p>
                          <img src="../assets/popover1.png" />
                        </div>
                      </popover>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Dagtekening"
                        :rules="{
                          required: true,
                        }"
                      >
                        <input
                          :max="moment().format('YYYY-MM-DD')"
                          type="date"
                          id="Dagtekening"
                          class="transition duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                          :class="
                            dateValidation == 1
                              ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                              : dateValidation == 0
                              ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                              : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                          "
                          v-model="form.text_answers[1393]"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                      <small v-if="dateValidation == 0" class="text-red-500"
                        >Helaas, de bezwaartermijn van 6 weken is
                        verlopen</small
                      >
                    </div>
                    <!-- vorderingsnummer -->
                    <div class="my-4">
                      <label
                        for="Vorderingsnummer"
                        class="mb-2 text-sm font-medium text-gray-700 flex items-center justify-between"
                        ><span class="">
                          Vorderingsnummer
                          <small class="text-gray-600"
                            >(Beschikkings- of vorderingsnummmer)
                          </small></span
                        >
                        <button
                          class="hidden md:block"
                          v-popover:popover-2.right
                        >
                          <svg
                            class="w-4 h-4 ml-2 text-gray-400 hover:text-primary"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                        <button
                          class="block md:hidden"
                          v-popover:popover-2.left
                        >
                          <svg
                            class="w-4 h-4 ml-2 text-gray-400 hover:text-primary"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </label>
                      <popover name="popover-2" :event="event">
                        <div class="p-3 space-y-2">
                          <p>
                            Het aanslagnummer kunt u terugvinden op het
                            aanslagbiljet gemeentelijke belastingen.
                          </p>
                          <img src="../assets/popover2.jpg" />
                        </div>
                      </popover>
                      <validation-provider
                        v-slot="{ errors }"
                        name="Vorderingsnummer"
                        :rules="{
                          required: true,
                          regex: /^[0-9]*$/,
                        }"
                      >
                        <input
                          type="text"
                          id="Vorderingsnummer"
                          class="transition duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                          :class="
                            claimValidation == 1
                              ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                              : claimValidation == 0
                              ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                              : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                          "
                          v-model="form.text_answers[1231]"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                    </div>
                    <!-- WOZ-waarde -->
                    <div class="my-4">
                      <label
                        for="WOZ-waarde"
                        class="flex items-center justify-between mb-2 text-sm font-medium text-gray-700"
                        >WOZ-waarde
                        <button
                          class="hidden md:block"
                          v-popover:popover-3.right
                        >
                          <svg
                            class="w-4 h-4 ml-2 text-gray-400 hover:text-primary"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                        <button
                          class="block md:hidden"
                          v-popover:popover-3.left
                        >
                          <svg
                            class="w-4 h-4 ml-2 text-gray-400 hover:text-primary"
                            aria-hidden="true"
                            fill="currentColor"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0 11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0 011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
                              clip-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </label>
                      <popover name="popover-3" :event="event">
                        <div class="p-3 space-y-2">
                          <p>
                            De WOZ-waarde kunt u terugvinden op het
                            aanslagbiljet gemeentelijke belastingen. De
                            WOZ-waarde is de grondslag voor de gemeentelijke
                            heffingen.
                          </p>
                          <img src="../assets/popover3.jpg" />
                        </div>
                      </popover>
                      <p class="absolute mt-2.5 ml-2 z-10">€</p>
                      <validation-provider
                        v-slot="{ errors }"
                        name="WOZ-waarde"
                        :rules="{
                          required: true,
                          regex: /^(10[0-9]{1,}|[1-9][0-9]*(\.+)*0{3})$/,
                          max_value: 1000000,
                        }"
                      >
                        <input
                          type="text"
                          id="WOZ-waarde"
                          class="transition pl-6 duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                          :class="
                            valueValidation == 1
                              ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                              : valueValidation == 0
                              ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                              : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                          "
                          v-model="form.text_answers[1396]"
                          :error-messages="errors"
                          required
                        />
                      </validation-provider>
                    </div>
                  </div>
                </div>
              </div>
              <!-- form3 -->
              <div
                v-if="step == 3"
                class="grid md:grid-cols-3 sm:grid-cols-1 gap-5"
              >
                <div class="col-span-2">
                  <p class="text-primary font-semibold mb-1">Reden bezwaar</p>
                  <img :src="divider_lg" width="100%" />
                  <!-- Toelichting -->
                  <div class="my-4">
                    <label
                      for="Toelichting"
                      class="lock mb-2 text-sm font-medium text-gray-700"
                      >Toelichting
                    </label>
                    <textarea
                      id="Toelichting"
                      rows="10"
                      class="transition duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                      placeholder="Geef ons een toelichting waarom er volgens u bezwaar gemaakt moet worden. "
                      :class="
                        explanationValidation == 1
                          ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                          : explanationValidation == 0
                          ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                          : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                      "
                      v-model="form.text_answers[1427]"
                      :required="form.answers[1] != `3730`"
                    ></textarea>
                    <small class="text-gray-700">
                      <i>U kunt deze toelichting later nog aanvullen</i>
                    </small>
                  </div>
                </div>
              </div>
              <!-- form4 -->
              <div
                v-if="step == 4"
                class="grid md:grid-cols-3 sm:grid-cols-1 gap-5"
              >
                <!-- @click.prevent="switchToNextTab" -->

                <div class="col-span-2">
                  <p class="text-primary font-semibold mb-1">Contactgegevens</p>
                  <img :src="divider_lg" width="100%" />
                  <p>
                    Neem de gegevens over van uw aanslagbiljet gemeentelijke
                    belastingen.
                  </p>
                  <div class="devider">
                    <span class="my-5">Naam van persoon op aanslagbiljet</span>
                  </div>
                  <!-- Voorletters  -->
                  <div>
                    <label
                      for="Voorletters"
                      class="block mb-2 text-sm font-medium text-gray-700"
                      >Voorletters op aanslagbiljet</label
                    >
                    <input
                      @input="onInputChange"
                      ref="Voorletters"
                      type="text"
                      id="Voorletters"
                      class="transition duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                      :class="
                        voorlettersValidation == 1
                          ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                          : voorlettersValidation == 0
                          ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                          : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                      "
                      required
                    />
                  </div>
                  <!-- Achternaam  -->
                  <div>
                    <label
                      for="Achternaam"
                      class="block my-2 text-sm font-medium text-gray-700"
                      >Achternaam op aanslagbiljet
                    </label>
                    <validation-provider
                      v-slot="{ errors }"
                      name="Achternaam"
                      :rules="{
                        required: true,
                        regex: /^[a-zA-Z\s.,'-]{1,30}$/,
                        ErrorResponse: {
                          target: getErrorResponses.lastname,
                        },
                      }"
                    >
                      <input
                        type="text"
                        id="Achternaam"
                        class="transition duration-150 text-gray-800 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                        :class="
                          achternaamValidation == 1
                            ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                            : achternaamValidation == 0
                            ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                            : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                        "
                        v-model="form.lastname"
                        @input="handleFieldInput('lastname')"
                        :error-messages="errors"
                        required
                      />
                      <b-form-invalid-feedback
                        class="text-red-500"
                        v-if="achternaamValidation == 0"
                      >
                        Achternaam niet correct
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  <!-- phone -->
                  <div>
                    <label
                      for="Telefoonnummer"
                      class="block my-2 text-sm font-medium text-gray-700"
                      >Telefoonnummer</label
                    >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Telefoonnummer"
                      :rules="{
                        required: true,
                        regex: /^(0|\+?31|0031)([-\s]?\d[-\s]?){7,8}\d$/,
                        ErrorResponse: {
                          target: getErrorResponses.phone_number,
                        },
                      }"
                    >
                      <input
                        type="text"
                        id="Telefoonnummer"
                        class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                        :class="
                          phoneValidation == 1
                            ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                            : phoneValidation == 0
                            ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                            : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                        "
                        v-model="form.phone_number"
                        @input="handleFieldInput('phone_number')"
                        :error-messages="errors"
                        required
                      />
                      <b-form-invalid-feedback
                        class="text-red-500"
                        v-if="phoneValidation == 0"
                      >
                        Telefoonnummer niet correct
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  <!-- email -->
                  <div>
                    <label
                      for="E-mailadres"
                      class="block my-2 text-sm font-medium text-gray-700"
                      >E-mailadres</label
                    >
                    <validation-provider
                      v-slot="{ errors }"
                      name="E-mailadres"
                      :rules="{
                        required: true,
                        regex:
                          /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/,
                        ErrorResponse: {
                          target: getErrorResponses.email,
                        },
                      }"
                    >
                      <input
                        type="text"
                        id="E-mail adres"
                        class="transition duration-150 text-gray-900 border bg-gray-50 text-sm rounded-lg block w-full p-2.5"
                        :class="
                          emailValidation == 1
                            ? `border-green-500  focus:ring-green-200 focus:border-green-200`
                            : emailValidation == 0
                            ? `border-red-500  text-red-500  focus:ring-red-200 focus:border-red-200`
                            : `border border-gray-200  focus:ring-gray-200 focus:border-gray-200`
                        "
                        v-model="form.email"
                        :error-messages="errors"
                        @input="handleFieldInput('errors')"
                        required
                      />
                      <b-form-invalid-feedback
                        class="text-red-500"
                        v-if="emailValidation == 0"
                      >
                        E-mail adres niet correct
                      </b-form-invalid-feedback>
                    </validation-provider>
                  </div>
                  <small class="text-gray-700"
                    ><i>
                      Wij verwerken uw contactgegevens zoals omschreven in ons
                      privacybeleid</i
                    ></small
                  >
                </div>
                <div class="col-span-1 mt-[85px] md:block hidden">
                  <div class="alert_message alert_message_desktop">
                    De benaming die u hier gaat invullen moet
                    <b>overeenkomen met uw aanslagformulier!</b>
                  </div>
                </div>
              </div>
              <!-- form5 -->
              <div v-if="step == 5">
                <div class="grid md:grid-cols-3 sm:grid-cols-1 gap-10">
                  <div class="col-span-2">
                    <p class="text-primary font-semibold mb-1">Afronden</p>
                    <img :src="divider_lg" width="100%" />
                    <p class="my-5">
                      Controleer onderstaande gegevens nog eenmaal goed.
                    </p>

                    <div
                      class="flex justify-between w-full flex-row sm:text-sm"
                      v-if="form.house_number"
                    >
                      <p class="text-start text-cs-mobile">Locatie:</p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.street }} {{ form.house_number }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="form.zip"
                    >
                      <p class="text-start text-cs-mobile">
                        Postcode en plaats:
                      </p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.zip }} {{ form.city }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="form.email"
                    >
                      <p class="text-start text-cs-mobile">E-mailadres:</p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.email }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="form.phone_number"
                    >
                      <p class="text-start text-cs-mobile">Telefoonnummer:</p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.phone_number }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="
                        form.text_answers[1393] != undefined &&
                        form.text_answers[1393] != ''
                      "
                    >
                      <p class="text-start text-cs-mobile">Dagtekening :</p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.text_answers[1393] }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="
                        form.text_answers[1231] != undefined &&
                        form.text_answers[1231] != ''
                      "
                    >
                      <p class="text-start text-cs-mobile">
                        Aanslagbiljetnummer:
                      </p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.text_answers[1231] }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="
                        form.text_answers[1396] != undefined &&
                        form.text_answers[1396] != ''
                      "
                    >
                      <p class="text-start text-cs-mobile">WOZ-waarde:</p>
                      <p class="text-end text-cs-mobile">
                        <b>€ {{ form.text_answers[1396] }}</b>
                      </p>
                    </div>

                    <div
                      class="flex justify-between w-full flex-row"
                      v-if="form.lastname"
                    >
                      <p class="text-start text-cs-mobile">
                        Naam belastingplichtige:
                      </p>
                      <p class="text-end text-cs-mobile">
                        <b>{{ form.firstname }} {{ form.lastname }}</b>
                      </p>
                    </div>

                    <!-- <div class="flex justify-between w-full flex-row">
                      <p class="text-start text-cs-mobile">Waardepeildatum:</p>
                      <p class="text-end text-cs-mobile"><b>2022-01-01</b></p>
                    </div> -->

                    <p class="text-primary font-semibold mb-1 mt-5">
                      Ondertekenen
                    </p>
                    <img :src="divider_lg" width="100%" />
                  </div>

                  <div
                    class="col-span-1 mt-[183px] md:block hidden"
                    v-if="
                      form.text_answers[1396] != undefined &&
                      form.text_answers[1396] != ''
                    "
                  >
                    <div class="alert_message alert_message_desktop">
                      Controleer deze <b>dagtekening</b> goed om een te laat
                      ingediend bezwaarschift te voorkomen.
                    </div>
                  </div>
                </div>
                <div>
                  <div class="flex items-center mb-4 mt-5">
                    <!-- <input
                      id="default-radio-1"
                      type="radio"
                      value="3848"
                      name="default-radio"
                      v-model="signPad"
                      class="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2"
                    /> -->
                    <!-- <label
                      for="default-radio-1"
                      class="ml-2 text-sm font-medium text-gray-900"
                      >Digitaal ondertekenen
                      <span>(snel en veilig)</span></label
                    > -->
                  </div>
                  <div class="flex items-center mb-4 mt-5">
                    <!-- <input
                      checked
                      id="default-radio-2"
                      type="radio"
                      value="3851"
                      name="default-radio"
                      v-model="signPad"
                      class="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2"
                    /> -->
                    <!-- <label
                      for="default-radio-2"
                      class="ml-2 text-sm font-medium text-gray-900"
                      >Per email ontvangen, printen, ondertekenen en
                      terugsturen</label
                    > -->
                  </div>
                  <div v-if="signPad == `3848`">
                    <p class="my-5">
                      Zet hieronder uw handtekening om ons toestemming te geven
                      om namens u WOZ bezwaar te maken. Onze dienst is voor u
                      <b>altijd gratis</b>. Probeer met uw muis zo goed mogelijk
                      uw handtekening na te maken. Gebruik het tekenvlak
                      <b>zo groot mogelijk</b>. Uw handtekening hoeft niet
                      perfect te zijn.
                    </p>
                    <div>
                      <vueSignature
                        class="border border-gray-500"
                        ref="signature"
                        :sigOption="option"
                        :w="'100%'"
                        :h="'300px'"
                      ></vueSignature>
                      <div class="flex items-end justify-between my-5">
                        <p>
                          U ondertekent hiermee de
                          <span class="text-primary">machtiging</span>
                        </p>
                        <p
                          class="text-primary text-sm cursor-pointer hover:text-gray-700"
                          @click="clearSignature"
                        >
                          Handtekening wissen
                        </p>
                      </div>
                    </div>
                  </div>
                  <p v-else class="my-5">
                    U ontvangt per e-mail de machtiging en overeenkomst zodat u
                    deze kunt uitprinten en ondertekenen.
                  </p>
                </div>
              </div>
              <div class="pt-4" v-if="step == 1">
                <p class="text-primary font-semibold mb-1">Onroerend goed</p>
                <img :src="divider_lg" width="100%" />
                <p class="my-5">Uw woonsituatie</p>
                <div class="flex items-center mb-4">
                  <input
                    id="default-radio-1"
                    type="radio"
                    value="3730"
                    v-model="form.answers[1]"
                    required
                    name="default-radio"
                    class="w-4 h-4 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2"
                  />
                  <label
                    for="default-radio-1"
                    class="ml-2 text-sm font-medium text-gray-900"
                    >Koop</label
                  >
                  <input
                    checked
                    id="default-radio-2"
                    type="radio"
                    value="3733"
                    v-model="form.answers[1]"
                    required
                    name="default-radio"
                    class="w-4 h-4 ml-8 text-primary bg-gray-100 border-gray-300 focus:ring-primary focus:ring-2"
                  />
                  <label
                    for="default-radio-2"
                    class="ml-2 text-sm font-medium text-gray-900"
                    >Huur</label
                  >
                </div>
              </div>
              <!-- buttons -->
              <div
                class="flex flex-col md:flex-row items-end justify-between"
                v-if="step <= 5"
              >
                <div>
                  <p
                    v-if="step > 1 && !showLoginBtn"
                    class="text-primary underline text-sm cursor-pointer"
                    @click="step--, (showBtn = `true`)"
                  >
                    Vorige pagina
                  </p>
                </div>
                <a
                  v-if="showLoginBtn"
                  href="https://mijn.bezwaarmaker.nl/login"
                  class="mt-6 no-underline mb-1 inline-block px-6 py-4 bg-green-600 border-b-2 border-green-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                  target="_blank"
                >
                  Dit adres lijkt bij ons al bekend te zijn. Log in om verder te
                  gaan
                </a>
                <button
                  v-if="!showLoginBtn"
                  type="submit"
                  :disabled="getLoader"
                  class="mt-6 mb-1 inline-block px-6 py-4 bg-green-600 border-b-2 border-green-800 text-white font-medium text-xs leading-tight uppercase rounded-full shadow-md hover:bg-green-600 hover:shadow-lg focus:bg-green-600 focus:shadow-lg focus:outline-none focus:ring-0 active:bg-green-700 active:shadow-lg transition duration-150 ease-in-out"
                >
                  <div role="status" v-if="getLoader || buttonLoader">
                    <svg
                      aria-hidden="true"
                      class="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-red-600"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
                  <span v-else class="flex items-center">
                    <span v-if="step == 1"> 2. Aanslaggegevens </span>
                    <span v-else-if="step == 2">
                      Naar stap 3. Reden bezwaar
                    </span>
                    <span v-else-if="step == 3">
                      Naar stap 4. Contactgegevens
                    </span>
                    <span v-else-if="step == 4"> Naar stap 5 - Afronden </span>
                    <span v-else-if="step == 5"> Afronden </span>
                    <img class="ml-5" :src="arrowIcon"
                  /></span>
                </button>
              </div>
            </form>
          </validation-observer>
          <Bedankt v-if="step == 6" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bedankt from "@/components/Bedankt.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import vueSignature from "vue-signature";
import divider_lg from "@/assets/divider_lg.png";
import arrowIcon from "@/assets/btnArrow.png";
import {
  required,
  digits,
  email,
  max,
  regex,
  max_value,
} from "vee-validate/dist/rules";
import {
  extend,
  ValidationObserver,
  ValidationProvider,
  setInteractionMode,
} from "vee-validate";
setInteractionMode("eager");
extend("digits", {
  ...digits,
  message: "{_field_} needs to be {length} digits. ({_value_})",
});
extend("required", {
  ...required,
  message: "verplicht",
});
extend("max", {
  ...max,
  message: "{_field_} may not be greater than {length} characters",
});
extend("max_value", {
  ...max_value,
  message: "{_field_} may not be greater than {length} characters",
});
extend("regex", {
  ...regex,
  message: "{_value_} : ongeldig formaat",
});
extend("email", {
  ...email,
  message: "Email must be valid",
});
extend("ErrorResponse", {
  params: ["target"],
  validate(value, { target }) {
    console.log("errorrresponse", value, this.getErrorResponses, target);
    return target != null ? target : true;
  },
  message: "{_field_} must be valid",
});
export default {
  name: "formSection",
  components: {
    ValidationProvider,
    ValidationObserver,
    vueSignature,
    Bedankt,
  },
  props: {
    event: {
      type: String,
      default: "hover",
    },
  },
  data() {
    return {
      //field: true,
      bootCheck: false,
      step: 1,
      validationCheck: false,
      dateValidate: true,
      showLoginBtn: false,
      divider_lg,
      arrowIcon,
      explanation: "",
      showBtn: "true",
      clearAnswers: true,
      buttonLoader: false,
      form: {
        language: "nl_NL",
        site_custom_url: "https://bezwaarmaker.besparenopwoz.nl",
        site_custom_name: "woz_branded_bezwaarmaker",
        publisher_id: "",
        site_subid: "",
        zip: "",
        house_number: "",
        street: "",
        toev: "",
        city: "",
        firstname: "",
        lastname: "",
        email: "",
        phone_number: "",
        answers: ["3277", "", ""],
        text_answers: [],
      },
      option: {
        penColor: "rgb(0, 0, 0)",
        backgroundColor: "rgb(255,255,255)",
      },
      signPad: "3848",
    };
  },
  methods: {
    handleFieldInput(fieldName) {
      console.log("phone number changed", fieldName);
      if (this.getErrorResponses && !this.getErrorResponses[fieldName]) {
        console.log("phone number changed2", fieldName);
        this.$store.commit("setError", {
          ...this.getErrorResponses,
          [fieldName]: true,
        });
      }
    },
    async handleForm() {
      if (!this.buttonLoader && this.dateValidate) {
        if (this.step == 5 && this.signPad == `3848`) {
          this.form.text_answers[1411] = this.$refs.signature.save();
          this.form.answers.push(this.signPad);
        }
        if (this.step == 5 && this.signPad == `3851`) {
          this.form.answers.push(this.signPad);
        }
        if (this.step == 5) {
          // this.form.text_answers[1231] = "." + this.form.text_answers[1231];
          // let resp = await console.log(this.form);
          this.form.text_answers[1396] = this.form.text_answers[1396].replace(
            /\./g,
            ""
          );
          if (this.form.text_answers[1430] == "999") {
            let zipData = {
              house_number: this.form.house_number,
              zip: this.form.zip,
              toev: this.form.toev,
            };
            await this.saveMissedMun(zipData);
          }
          const phoneNumberWithoutPunctuation = this.form.phone_number
            .replace(/[\s-]/g, "")
            .replace(/^00(\d+)$/, "$1");
          let resp = await this.postLead({
            ...this.form,
            bootCheck: this.bootCheck,
            phone_number: phoneNumberWithoutPunctuation,
          });
          if (resp.success) {
            this.showBtn == "true" ? (this.step = 6) : (this.step = 7);
          } else if (
            this.getErrorResponses.zip == false ||
            this.getErrorResponses.house_number == false ||
            this.getErrorResponses.street == false ||
            this.getErrorResponses.city == false
          ) {
            this.step = 1;
            this.$emit("steps");
          } else if (
            this.getErrorResponses.lastname == false ||
            this.getErrorResponses.email == false ||
            this.getErrorResponses.phone_number == false
          ) {
            this.step = 4;
            this.$emit("steps");
          }
          await this.$refs.myForm.validate();
          for (const key in this.getErrorResponses) {
            if (
              this.getErrorResponses.hasOwnProperty(key) &&
              this.getErrorResponses[key] === false
            ) {
              // firstFalseKey = key;
              console.log("key", key);
              this.$refs[key].focus();
              this.$refs[key].blur();

              break;
            }
          }
        }
        this.step < 5 ? this.step++ : "";
      }
    },
    async objectionRequestCheck(payload) {
      let resp = await this.objectionRequest(payload);
      if (resp.success) {
        this.showLoginBtn =
          resp.responseData.address_exists || resp.responseData.email_exists;
      }
    },
    async setCity() {
      let postcode = this.form.zip;
      let house = this.form.house_number;
      let toev = this.form.toev;
      let res = await this.getPostcodeData({ postcode, house, toev });
      if (res) {
        this.form.street = res.street;
        this.form.city = res.city;
      }
    },
    onInputChange(e) {
      let inputValue = e.target.value;
      let fieldRef = this.$refs.Voorletters;
      if (inputValue.length < this.form.firstname.length) {
        fieldRef.value = inputValue.slice(0, -1);
        this.form.firstname = inputValue;
      } else {
        let newVal = fieldRef.value + ".";
        fieldRef.value = newVal.toUpperCase();
        this.form.firstname = fieldRef.value;
      }
    },
    clearSignature() {
      var _this = this;
      _this.$refs.signature.clear();
    },
    isLessThan42DaysAway(date) {
      // Convert the input date to a Moment.js object
      const momentDate = moment(date);

      // Calculate the difference between the input date and today's date in days
      //const diffInDays = moment().diff(momentDate, "days");
      const isPast = momentDate.isBefore(moment(), "day");
      return isPast;
      //Check if the difference is less than 42 days
      // return diffInDays < 42;
    },
  },

  created() {
    this.form.publisher_id = this.$route.query.site;
    this.form.site_subid = this.$route.query.oa_id;
  },

  watch: {
    "form.house_number": async function (newVal, oldVal) {
      this.setCity();
      let zipData = {
        house: this.form.house_number,
        zip: this.form.zip,
        toev: this.form.toev,
      };
      this.buttonLoader = true;
      let res = await this.getMunicipalityId(zipData);
      if (res.success) {
        this.form.text_answers[1430] = res.responseData;
        this.buttonLoader = false;
      } else {
        this.form.text_answers[1430] = "999";
        this.buttonLoader = false;
      }
    },
    "form.toev": async function (newVal, oldVal) {
      this.setCity();
      let zipData = {
        house: this.form.house_number,
        zip: this.form.zip,
        toev: this.form.toev,
      };
      this.buttonLoader = true;
      let res = await this.getMunicipalityId(zipData);
      if (res.success) {
        this.form.text_answers[1430] = res.responseData;
        this.buttonLoader = false;
      } else {
        this.form.text_answers[1430] = "999";
        this.buttonLoader = false;
      }
    },
    "form.city": async function (newVal, oldVal) {
      let payload = {
        zip_code: this.form.zip,
        house_number: this.form.house_number,
        street: this.form.street,
        city: this.form.city,
        house_number_addition: this.form.toev,
      };
      this.objectionRequestCheck(payload);
    },
    "form.email": function (newVal, oldVal) {
      if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(newVal)) {
        let payload = {
          email: newVal,
        };
        this.objectionRequestCheck(payload);
      }
    },
    showBtn(newValue, oldValue) {
      if (newValue == "false") {
        setTimeout(() => {
          (this.form.text_answers[1396] = ""),
            (this.form.text_answers[1231] = ""),
            (this.form.text_answers[1393] = ""),
            (this.step = 4);
        }, 500);
      }
    },
    step(newValue, oldValue) {
      console.log("Step changed...", newValue);
      if (oldValue == 1 && newValue == 2 && this.clearAnswers) {
        this.form.text_answers[1231] = "";
        this.form.text_answers[1393] = new Date().toISOString().split("T")[0];
        this.form.text_answers[1396] = "";
        this.clearAnswers = false;
      }
      // if (newValue == 5) {
      //   window.scrollTo(0, 0);
      // }
      if (newValue == 4) {
        setTimeout(() => {
          this.$refs.Voorletters.value = this.form.firstname;
        }, 0);
      }
      setTimeout(() => {
        // window.scrollTo({
        //   top: 0,
        //   left: 0,
        //   behavior: "smooth",
        // });
        const element = document.getElementById("form");

        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 100);
    },
  },
  computed: {
    ...mapGetters(["getErrorResponses", "profId"]),

    zipValidation() {
      // if (this.form.zip == "") {
      //   return 2;
      // }
      // let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
      // if (this.form.zip.match(zipRegex)) {
      //   return 1;
      // } else {
      //   return 0;
      // }
      if (this.form.zip == "" && !this.getErrorResponses.zip) {
        this.validationCheck = false;
        return null;
      }
      let zipRegex = /^([0-9]{4}\s?[a-zA-Z]{2})$/;
      if (this.getErrorResponses.zip == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.zip.match(zipRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    houseValidation() {
      // if (this.form.house_number == "") {
      //   return 2;
      // }
      // let houseRegex = /(\w?[0-9]+[a-zA-Z0-9\- ]*)/;

      // if (this.form.house_number.match(houseRegex)) {
      //   return 1;
      // } else {
      //   return 0;
      // }
      if (
        this.form.house_number == "" &&
        !this.getErrorResponses.house_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[0-9]*$/;
      if (this.getErrorResponses.house_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.house_number.match(streetRegex)) {
        this.validationCheck = true;
        return 1;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    toevValidation() {
      if (this.form.toev === "" && !this.getErrorResponses.toev) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-z\s]+$/i;
      if (this.getErrorResponses.toev === false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.toev.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },

    streetValidation() {
      // if (this.form.street == "") {
      //   return 2;
      // }

      // if (this.form.street != "") {
      //   return 1;
      // } else {
      //   return 0;
      // }
      if (this.form.street == "" && !this.getErrorResponses.street) {
        this.validationCheck = false;
        return null;
      }

      let streetRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.getErrorResponses.street == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.street.match(streetRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    cityValidation() {
      // if (this.form.city == "") {
      //   return 2;
      // } else {
      //   return 1;
      // }
      if (this.form.city == "" && !this.getErrorResponses.city) {
        this.validationCheck = false;
        return null;
      }

      // let streetRegex = /^[a-zA-Z ]*$/;
      if (this.getErrorResponses.city == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.city.match()) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    propertyValidation() {
      if (this.form.answers[2] == "") {
        return 2;
      } else {
        return 1;
      }
    },
    // step 2
    dateValidation() {
      if (
        this.form.text_answers[1393] == new Date().toISOString().split("T")[0]
      ) {
        this.validationCheck = false;
        return 2;
      }
      if (this.form.text_answers[1393] != "") {
        const selectedDate = new Date(this.form.text_answers[1393]);
        const isLessThan42 = this.isLessThan42DaysAway(selectedDate);
        if (isLessThan42) {
          this.validationCheck = true;
          this.dateValidate = true;
          return 1;
        } else {
          this.dateValidate = false;
          this.validationCheck = false;
          return 0;
        }
      }
    },
    // dateValidation() {
    // if (
    //   this.form.text_answers[1393] == new Date().toISOString().split("T")[0]
    // ) {
    //   this.validationCheck = false;
    //   return 2;
    // }
    // if (this.form.text_answers[1393] != "") {
    //   const selectedDate = new Date(this.form.text_answers[1393]);
    //   this.validationCheck = true;
    //   this.dateValidate = true;
    //   return 1;
    // } else {
    //   this.dateValidate = false;
    //   this.validationCheck = false;
    //   return 0;
    // }
    // },
    claimValidation() {
      if (this.form.text_answers[1231] == "") {
        this.validationCheck = false;
        return 2;
      }

      let regex = /^[0-9]*$/;

      if (this.form.text_answers[1231]?.match(regex)) {
        this.validationCheck = true;
        return 1;
      } else {
        this.validationCheck = false;
        return 0;
      }
    },
    valueValidation() {
      if (this.form.text_answers[1396] == "") {
        this.validationCheck = false;
        return 2;
      }

      let inputValue = this.form.text_answers[1396];
      let regex = /^(10[0-9]{1,}|[1-9][0-9]*(\.+)*0{3})$/;

      if (inputValue.match(regex)) {
        this.validationCheck = true;
        return 1;
      } else {
        this.validationCheck = false;
        return 0;
      }
    },

    // step 3
    explanationValidation() {
      if (this.form.text_answers[1427] == undefined) {
        return 2;
      } else {
        return 1;
      }
    },
    // step4
    voorlettersValidation() {
      // if (this.form.firstname == "") {
      //   this.validationCheck = false;
      //   return 2;
      // }
      // if (this.form.firstname != "") {
      //   this.validationCheck = true;
      //   return 1;
      // } else {
      //   this.validationCheck = false;
      //   return 0;
      // }
      if (this.form.firstname == "" && !this.getErrorResponses.firstname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;
      if (this.getErrorResponses.firstname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.firstname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    achternaamValidation() {
      // if (this.form.lastname == "") {
      //   this.validationCheck = false;
      //   return 2;
      // }
      // let nameRegex = /^([a-zA-Z ]){1,30}$/;
      // if (this.form.lastname.match(nameRegex)) {
      //   this.validationCheck = true;
      //   return 1;
      // } else {
      //   this.validationCheck = false;
      //   return 0;
      // }
      if (this.form.lastname == "" && !this.getErrorResponses.lastname) {
        this.validationCheck = false;
        return null;
      }
      let nameRegex = /^[a-zA-Z\s.,'-]{1,30}$/;

      if (this.getErrorResponses.lastname == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.lastname.match(nameRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    emailValidation() {
      // if (this.form.email == "") {
      //   this.validationCheck = false;
      //   return 2;
      // }
      // if (
      //   /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.form.email)
      // ) {
      //   this.validationCheck = true;
      //   return 1;
      // } else {
      //   this.validationCheck = false;
      //   return 0;
      // }
      if (this.form.email == "" && !this.getErrorResponses.email) {
        this.validationCheck = false;
        return null;
      }
      if (this.getErrorResponses.email == false) {
        this.validationCheck = false;
        return false;
      } else if (
        /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9_.-]+\.[a-zA-Z]{2,}$/.test(this.form.email)
      ) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
    phoneValidation() {
      if (
        this.form.phone_number == "" &&
        !this.getErrorResponses.phone_number
      ) {
        this.validationCheck = false;
        return null;
      }

      let phoneRegex = /^(0|\+31|0031|00 31)([-\s]?\d[-\s]?){9}$/;
      if (this.getErrorResponses.phone_number == false) {
        this.validationCheck = false;
        return false;
      } else if (this.form.phone_number == "0") {
        this.validationCheck = false;
        return true;
      } else if (this.form.phone_number.match(phoneRegex)) {
        this.validationCheck = true;
        return true;
      } else {
        this.validationCheck = false;
        return false;
      }
    },
  },
};
</script>

<style>
.hidden-checkbox {
  opacity: 0;
}
.formSection {
  background-color: #d7dadb;
}
@media only screen and (max-width: 767.98px) {
  .fs--8 {
    font-size: 8px;
  }
}
[data-popover="popover-1"] {
  width: 300px !important;
  line-height: 1.2;
}
[data-popover="popover-2"] {
  width: 300px !important;
  line-height: 1.2;
}
[data-popover="popover-3"] {
  line-height: 1.2;
  width: 300px !important;
}
[data-popover="popover-4"] {
  line-height: 1.2;
  width: 300px !important;
}
.devider {
  color: #ff4900;
  margin: 20px 0;
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
.devider:after {
  content: "";
  height: 1px;
  background: #ff4900;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}
.alert_message {
  padding: 15px;
  border-radius: 4px;
  background: #f2f2f2;
  position: relative;
}
.alert_message:before {
  content: "";
  display: block;
  position: absolute;
  top: 8px;
  left: -7px;
  width: 0;
  height: 0;
  border-top: 11px solid transparent;
  border-bottom: 11px solid transparent;
  border-right: 11px solid #f2f2f2;
}
.alert_message:after {
  width: 16px;
  height: 16px;
  border-radius: 16px;
  text-align: center;
  font-size: 0.625rem;
  background: #ff4900;
  font-weight: 700;
  content: "!";
  display: block;
  top: 10px;
  left: -36px;
  color: #fff;
  position: absolute;
  -webkit-box-shadow: 0 0 0 5px #fff;
  box-shadow: 0 0 0 5px #fff;
}

@media only screen and (max-width: 767.98px) {
  .alert_message {
    display: none;
  }
}
@media only screen and (max-width: 460px) {
  .text-cs-mobile {
    font-size: 9px;
  }
}
</style>
