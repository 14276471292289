<template>
  <div class="home">
    <Banner />
    <Hoeveel />
    <Stappen />
    <!-- <FormSection /> -->
    <Footer />
  </div>
</template>

<script>
// @ is an alias to /src
import Banner from "@/components/Banner.vue";
import Hoeveel from "@/components/Hoeveel.vue";
import Stappen from "@/components/Stappen.vue";
import FormSection from "@/components/FormSection.vue";
import Footer from "@/components/Footer.vue";

export default {
  name: "HomeView",
  components: {
    Banner,
    Hoeveel,
    Stappen,
    FormSection,
    Footer,
  },
  mounted() {
    let useScript = document.createElement("script");

    useScript.setAttribute(
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";

        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      ),
      fbq("init", "969816563708997"),
      fbq("track", "PageView")
    ),
      document.head.appendChild(useScript);
  },

  created() {
    document.title = "";
    this.noscriptHtml =
      '<noscript><img height="1" width="1" style="display:none"src="https://www.facebook.com/tr?id=969816563708997&ev=PageView&noscript=1"/></noscript>';
  },
};
</script>
<style></style>
