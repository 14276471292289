import { RepositoryFactory } from "../../repository/RepositoryFactory";
import axios from "axios";
import firebase from "../../config/firebase";
let leads = RepositoryFactory.get("leads");
let street = RepositoryFactory.get("street");
const state = {
  getLoader: false,
  getObjectionLoader: false,
  id: null,
  getObjectionResponse: null,
  getErrorResponse: {
  }
};

const getters = {
  getLoader: (state) => state.getLoader,
  getObjectionLoader: (state) => state.getObjectionLoader,
  profId: (state) => state.id,
  getObjectionResponse: (state) => state.getObjectionResponse,
  getErrorResponses: (state) => state.getErrorResponse,

};
const actions = {
  async objectionRequest({ commit }, payload) {
    const request = {
      method: "POST",
      url: "https://services.bezwaarmaker.nl/website-api/objection-request/temp",
      headers: { Authorization: "Bearer 9537f407-7388-11ec-afea-42010a479003" },
    };
    let requestData = { ...request, data: payload };
    commit("setObjectionLoader", true);
    try {
      const response = await axios.request(requestData);
      const responseData = response.data?.data;
      commit("setObjectionLoader", false);
      commit("setObjectionResponse", responseData);
      return {
        success: true,
        responseData: responseData,
      };
    } catch (error) {
      commit("setObjectionLoader", false);
      alert(error.response.data);
      return {
        success: false,
      };
    }
  },

  async getMunicipalityId({ commit }, payload) {
    const request = {
      method: "GET",
      url:
        "https://services.bezwaarmaker.nl/website-api/address-info?zip_code=" +
        payload.zip +
        "&house_number=" +
        payload.house +
        "&house_number_addition=" + payload.toev,
      headers: { Authorization: "Bearer 9537f407-7388-11ec-afea-42010a479003" },
    };
    try {
      const response = await axios.request(request);
      const responseData = response.data?.data?.municipality?.id;
      return {
        success: true,
        responseData: responseData,
      };
    } catch (error) {
      console.log(error.response.data);
      return {
        success: false,
      };
    }
  },
  // test
  async postLead({ commit }, payload) {
    commit("setGetLoader", true);
    //   //test
    //   const testError = "Validation error for 'phone_number'";
    //   const error = testError.match(/'([^']+)'/)[0].slice(1, -1);
    //   commit("setError", { [error]: false });
    //   commit("setGetLoader", false);
    //   return {
    //     success: false,
    //   };
    // live
    try {
      if (payload.bootCheck == true) {
        commit("setGetLoader", false);

        return {
          success: true,
        };
      } else {
        const response = await leads.postLead(payload);
        commit("setGetLoader", false);
        const responseid = response.data.profile.id;
        commit("id", responseid);
        return {
          success: true,
          profileid: responseid,
        };
      }

    } catch (error) {
      commit("setGetLoader", false);
      if (error.response && error.response.data.error === "Conflict - duplicate") {
        console.log("error=>", error.response.data.error)

        commit("id", null);
        return {
          success: true,
        };
      } else {
        const errorKey = error.response.data.error.match(/'([^']+)'/)[0].slice(1, -1);
        commit("setError", { [errorKey]: false });
        console.error("Error Detail:", errorKey);
        return {
          success: false,
        };
      }
    }
  },

  async getPostcodeData({ commit }, payload) {
    try {
      const response = await street.getStreet(payload);
      return {
        city: response.data.settlement,
        street: response.data.street,
      };
    } catch (error) {
      console.log(error);
      // console.log(error.response.data.error);
    }
  },
  async saveMissedMun({ commit }, payload) {
    console.log("payload=>", payload);
    const payloadWithCreatedAt = {
      ...payload,
      createdAt: firebase.firestore.FieldValue.serverTimestamp()
    };
    firebase.firestore().collection("woz_missed_mun").add(payloadWithCreatedAt);
  }

};

const mutations = {
  setGetLoader(state, payload) {
    state.getLoader = payload;
  },
  setObjectionLoader(state, payload) {
    state.getObjectionLoader = payload;
  },
  setObjectionResponse(state, payload) {
    state.getObjectionResponse = payload;
  },
  id(state, payload) {
    state.id = payload;
  },
  setError(state, payload) {
    state.getErrorResponse = payload;
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
