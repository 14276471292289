<template>
  <div class="">
    <div class="container pt-7 grid md:grid-cols-2 sm:grid-cols-1 gap-5">
      <div class="flex content_left">
        <div class="ml-auto">
          <h2 class="py-4 text-primary">
            In 5 stappen <br />
            naar uw besparing
          </h2>
          <p>Al <b>105.966</b> particulieren maakte ook gratis bezwaar</p>
          <img :src="Badge" class="ml-auto md:mt-14 badgeImg" />
        </div>
      </div>
      <div>
        <div v-for="(data, index) in points" :key="index" class="mb-7">
          <div class="flex mb-5">
            <!-- <img :src="data.img" width="45px" /> -->
            <img
              :src="data.img"
              width="45px"
              :style="{ height: index === 1 ? '100%' : 'auto' }"
            />
            <h6
              class="text-xl font-semibold tracking-tight mb-0 mt-auto ml-4 leading-none"
            >
              {{ data.title }}
            </h6>
          </div>
          <p class="font-normal text-sm text-gray-700">
            {{ data.text }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Badge from "@/assets/Badge.png";
import stappen1 from "@/assets/stappen1.png";
import stappen2 from "@/assets/stappen2.png";
import stappen3 from "@/assets/stappen3.png";
import stappen4 from "@/assets/stappen4.png";
import stappen5 from "@/assets/stappen5.png";

export default {
  name: "Stappen",
  data() {
    return {
      Badge,
      points: [
        {
          img: stappen1,
          title: "Aanmelden",
          text: "Meld u gemakkelijk binnen 2 minuten aan via ons aanmeldformulier en onderteken de machtiging. U Ontvangt na uw aanmelding nog een vragenlijst om de kans op succes te vergroten. Meld u aan, wij doen de rest.",
        },
        {
          img: stappen2,
          title: "Taxatieverslag opvragen bij uw gemeente -> WOZ-Waarde check",
          text: "Om de bezwaartermijn van 6 weken veilig te stellen, sturen we een pro-forma bezwaarschrift naar uw gemeente of belastingsamen- werking. We verzoeken hierin ook om het taxatieverslag toe te sturen.",
        },
        {
          img: stappen3,
          title: "Bezwaar motiveren of intrekken",
          text: "Na het indienen van het pro-forma bezwaarschrift zullen onze gediplomeerde WOZ- taxateurs het bezwaarschrift nader motiveren en op zoek gaan verkoopcijfers binnen uw gemeente welke een lagere waarde onderbouwen of in overleg met u het bezwaar intrekken als de waarde correct is vastgesteld.",
        },
        {
          img: stappen4,
          title: "Hoorzitting",
          text: "Indien de gemeente niet voornemens is de waarde te verlagen naar de door ons voorgestelde waarde zal er een hoorzitting plaats vinden. De hoorzitting is bedoeld om nieuwe grieven aan te dragen en het dossier te bespreken.",
        },
        {
          img: stappen5,
          title: "Uitspraak",
          text: "We ontvangen en beoordelen de uitspraak, de uitspraak wordt vervolgens naar u verstuurd. indien we niet tevreden zijn met de uitspraak kunnen we in overleg met u in beroep gaan bij een Rechtbank. Ook deze procedure is kosteloos voor u.",
        },
      ],
    };
  },
};
</script>

<style scoped>
.content_left {
  background-image: url(../assets/pict.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position-y: bottom;
}
.badgeImg {
  width: 160px;
}
@media only screen and (max-width: 767.98px) {
  .content_left {
    height: 430px;
  }
  .badgeImg {
    width: 100px;
  }
}
</style>
