import LeadRepository from "./LeadRepository";
import StreetRepository from "./StreetRepository";

const repositories = {
  leads: LeadRepository,
  street: StreetRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};
